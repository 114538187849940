"use client";
import Image from "next/image";
import rta from "../../public/images/rta.jpg";
import { useState, useEffect } from "react";

export default function GeoRestriction({ locale }: { locale: any }) {
  const [geo_de, setGeo_de] = useState("false");

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const geo_de = urlParams.get("geo_de");

    if (typeof window !== "undefined") {
      if (geo_de && geo_de === "true") {
        setGeo_de("true");
      }
    }
  }, []);

  return (
    <>
      {(geo_de === "true") && (
        <div className="fixed inset-0 flex bg-[#202124] px-4">
          <div className="m-auto flex max-w-[400px] flex-col rounded-md border-[0.5px] border-[#3C3C3C] bg-[#2B2D31] p-5">
            <div className="text-center text-sm font-medium text-white">
              {locale.geoWarning}
            </div>
            <div className="mt-6 pt-4 h-auto flex flex-row border-t border-[#3C3C3C]">
                  <div className="my-auto ml-auto mr-2 text-white text-sm">© 2024 CreatePorn AI</div>
                  <a href="https://www.rtalabel.org/" target="_blank" rel="noopener noreferrer nofollow" className="relative overflow-hidden w-[78px] h-[40px] my-auto mr-auto cursor pointer">
                    <Image
                      src={"https://cdn6.imgpog.com/rta.webp"}
                      alt="RTA"
                      width={80}  // These dimensions are now slightly larger than the container
                      height={50}
                      draggable="false"
                      className="ml-[-1px] mt-[-1.5px] absolute bottom-[-1.5px] right-[-1px]" />
                  </a>
                </div>
          </div>
        </div>
      )}
    </>
  );
}
