import React, { useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";

interface FullScreenModalProps {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  children: React.ReactNode;
}

const FullScreenModal: React.FC<FullScreenModalProps> = ({ showModal, setShowModal, children }) => {
  useEffect(() => {
    const handleEscape = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        setShowModal(false);
      }
    };

    document.addEventListener('keydown', handleEscape);
    return () => document.removeEventListener('keydown', handleEscape);
  }, [setShowModal]);

  return (
    <AnimatePresence>
      {showModal && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed inset-0 z-50 flex items-center justify-center pt-[5vh] bg-black bg-opacity-25 backdrop-blur"
          onClick={() => setShowModal(false)}
        >
          <motion.div
            className="w-full h-auto md:max-w-md md:max-h-screen md:rounded-lg md:overflow-hidden mb-auto"
            onClick={(e) => e.stopPropagation()} // Prevents click event from bubbling up to the overlay
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            {children}
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default FullScreenModal;
